import React from "react";
import styled from "styled-components";
import { Accordion, Button, Card, Container, Grid, Image } from "semantic-ui-react";
import { Link } from "gatsby";
type Mode = "dark" | "light";

/**************************************
 ********* Header Components **********
 **************************************/
export const HeaderH1 = styled.h1`
  font-size: 3rem;
  text-align: center;
  line-height: 1.125;

  @media (max-width: 769px) {
    font-size: 1.8em;
  }
`;

export const HeaderH3 = styled.h3`
  font-size: 1.5em;
  text-align: center;
  line-height: 1.125;
`;


export const CAABannerH2 = styled.h2`
  line-height: 1.125;
  font-weight: 400;

  + h3{
    margin-top: 0px;
  }
  
  @media (max-width: 769px) {
    font-size: 1.4em !important;
  }

  @media (max-width: 576px) {
    font-size: 1.1em !important;
  }
`;

export const CAABannerH3 = styled.h3`
  line-height: 1.125;
  font-weight: 400;
  
  @media (max-width: 769px) {
    font-size: 1.4em !important;
  }

  @media (max-width: 576px) {
    font-size: 1.1em !important;
  }
`;

/**************************************
 ******** Section Components **********
 **************************************/
export const ResponsiveContainer = styled(Container)<{tighten?: boolean}>`
  &&& {
    width: 100%;
    
    @media (max-width: 767px) {
      padding: 0 .5em !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    @media (min-width: 1024px) {
      max-width: 960px !important;
    }
      
    @media (min-width: 1216px) {
      max-width: 1152px !important;
    }

    @media (min-width: 1408px) {
      max-width: 1344px !important;
    }  

    ${props => props.tighten && `padding: 0 5em;` }
  }
`;

export const SectionH1 = styled.h1`
  font-size: 2rem;
  text-align: center;
  line-height: 1.125;

  @media (max-width: 769px) {
    font-size: 1.5em;
  }
`

export const SectionH2 = styled.h2<{left?: boolean}>`
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.125;
  margin-bottom: 1.5rem;
  ${props => props.left ? `text-align: left;` : `text-align: center;`}

  @media (max-width: 769px) {
    font-size: 1.5em;
  }
`;

export const SectionH3 = styled.h3`
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  line-height: 1.125;
  margin-bottom: 1.5rem;
  padding-bottom: 20px;

  @media (max-width: 769px) {
    font-size: 1.8em;
  }
`;

export const SectionH3Sub = styled(SectionH3)`
  max-width: 750px;
  margin: 1em auto 2.5em;
  font-weight: 400;
  font-size: 1.5em;
`;

export const SectionH4 = styled.h4`
  font-size: 1.25rem;
  text-align: center;
  font-weight: 600;
  line-height: 1.125;
  margin-bottom: 1.5rem;

  @media (max-width: 769px) {
    font-size: 1.5em;
  }
`;

export const SectionP = styled.p<{center?: boolean, right?: boolean, justify?: boolean}>`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1em;

  @media (max-width: 500px) {
    font-size: 16px;
  }

  ${props => props.center && `text-align: center;`}
  ${props => props.right && `text-align: right;`}
  ${props => props.justify && `text-align: justify;`}
`;


export const StyledAccordion = styled(Accordion)`
  .title {
    padding: 1rem 0 !important;
    border-bottom: 1px solid black;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    i { display: none !important; }
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='64px' height='64px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z' fill='%23000000'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      flex-shrink: 0;
      content: "";
      text-decoration: none;
      transition: transform 0.3s ease;
      display: inline-block;
      height: 1.5rem;
      width: 1.5rem;
    }
    &.active {
      border-color: transparent;
      &::after {
        transform: rotate(180deg);
      }
      & ~ .content:last-child {
        padding-bottom: 1rem !important;
      }
    }
  }
  .content {
    border-bottom: 1px solid black;
  }
`;

export const StyledButton = styled(Button)<{mode?: Mode}>`
  &&&& {
    font-family: "Shape", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
    font-size: 20px; 
    font-weight: 700;

    ${props => props.mode === "light" 
      ? `color: #0a0a0a; background-color: #f9f9f9;`
      : `color: #f9f9f9; background-color: #0a0a0a;`
    }

    ${props => props.large && `font-size: 1.25em;`}
    ${props => props.larger && `font-size: 1.5em;`}
  }
`;

export const Letter = styled.div<{center?: boolean}>`
  border: 1px solid #f1f1f1; 
  padding: 50px 25px;
  box-shadow: 0 10px 13px -7px #777, 5px 5px 15px 5px transparent;

  ${props => props.center && `text-align: center;`}
`;

export const BorderDiv = styled.div<{ mode: Mode, transparent?: boolean }>`
  color: ${props => props.mode === "light" ? "#0a0a0a" : "#ffffff" };
  background-color: ${props => props.mode === "light" ? "#ffffff" : "#0a0a0a"};
  border: 1px solid;
  border-color: ${props => props.mode === "light" ? "#0a0a0a" : "#ffffff" };
  padding: 2em;

  ${props => props.transparent && `background: 0 0;`}
`;

export const StyledCard = styled(Card)`
  &&&& {
    font-family: "Shape", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
    border-radius: 0;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);

    .image {
      border-radius: 0 !important;
    }

    .header {
      font-family: "Shape", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
    }
  }
`;

interface iStyledGridProps extends Grid {
  negativeMargin?: boolean;
  centered?: boolean
}

const StyledGrid:React.FC<iStyledGridProps> = ({negativeMargin, ...props }) => <Grid {...props} />;

export const ResponsiveGrid = styled(StyledGrid)<{negativeMargin?: boolean}>`
  &&&& {
    ${props => props.negativeMargin ? `margin: -.75rem;` : `margin: 0;`}
    
    
    @media screen and (min-width: 769px) {
      .column {
        width: 25%;
      }
    }

    @media screen and (min-width: 1024px) {
      .column {
          width: 16.66667%;
      }
    }
    
    
    @media screen and (max-width: 768px) {
      .column {
        width: 33.333333%;
      }
    }
  }
`;

export const ExecutiveName = styled.div`
  font-size: .9em;
  font-weight: 700;
`;

export const ExecutiveTitle = styled.div`
  font-size: .8em;
`;

/**************************************
 *********** Styled Links *************
 **************************************/
export const TakeAction = styled(Link)`
  color: white;
  text-decoration: underline;

  &:hover {
    color: #e6e6e6;
    text-decoration: underline;
  }
`;

export const UnderlinedLink = styled(Link)<{ mode: Mode }>`
  color: ${props =>  props.mode === "light" ? "#ffffff" : "#0a0a0a"};
  text-decoration: underline;

  &:hover {
    color: ${props => props.mode === "light" ? "#e6e6e6" : "#000000"};
    text-decoration: underline;
  }
`;

export const UnderlinedAnchor= styled.a<{ mode: Mode }>`
  color: ${props =>  props.mode === "light" ? "#ffffff" : "#0a0a0a"};
  text-decoration: underline;

  &:hover {
    color: ${props => props.mode === "light" ? "#e6e6e6" : "#000000"};
    text-decoration: underline;
  }
`;

export const HereLink = styled(Link)`
  color: white;
  text-decoration: underline;

  &:hover {
    color: #e6e6e6;
    text-decoration: underline;
  }
`;

/**************************************
 ********** Logo Components ***********
 **************************************/
export const LogoHiddenLabel = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  user-select: none;
  color: transparent;
`;

const Logo = styled(Image)`
  &&& {
    width: 100%;
    display: block;
    margin-bottom: 3em;

    @media (max-width: 769px) {
      margin: auto;
      margin-bottom: 2em;
    }
  }
`;

export const FooterLogo = styled(Logo)`
  &&&&&&& {
    max-width: 100px;
    margin-bottom: 0;

    @media (min-width: 770px) {
      margin: 0;
      margin-bottom: 0;
    }
  }
`;

export const HeaderLogo = styled(Logo)`
  &&& {
    display: inline-block;
    max-width: 150px;

    @media (max-width: 769px) {
      max-width: 100px;
    }
  }
`;


export const LetterLogo = styled(Logo)`
  &&& {
    max-width: 150px;
    margin: 0 auto 2em;
  }
`;


export const PartnerLogo = styled(Image)`
  margin: 25px auto; 
  width: ${props => {
    let logoSize;
    switch (props.logosize) {
      case "extra small":
        logoSize = "60%";
        break;
      case "small":
        logoSize = "70%";
        break;
      case "medium":
        logoSize = "80%"
        break;
      case "large":
        logoSize = "90%";
        break;
      case "extra large":
        logoSize = "100%";
      default:
        logoSize = "80%";
        break;
    }

    return logoSize;
  }};

  @media (max-width: 767px) {
    max-width: 100px; 
  }
`;