import React, { useState } from "react";
import { Link } from "gatsby";
import { Menu, Sidebar, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import CivicAllianceLogo from "../../static/mediaLogos/civic-alliance-logo.png";


const MenuItems = ()=>{
  return(
    <>
      <Menu.Item className="menuItem" as="a" style={{ color: "#fff200" }} content="Corporate Civic Playbook" href="https://playbook.civicalliance.com" target="_blank" />
      <Menu.Item className="menuItem" as="a" content="Join Us" href="https://cvcallnce.com/join" target="_blank" />
      <Menu.Item className="menuItem" as={Link} content="Members" to="/members" />
      <Dropdown className="menuItem" item text="Initiatives">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} text="100% In for Democracy" to="/100"/>
          <Dropdown.Item as={Link} text="Protecting Voting Access" to="/votingaccess" />
          <Dropdown.Item as={Link} text="Time to Vote" to="/TimeToVote" />
          <Dropdown.Item as={Link} text="Election Day of Service" to="/service" />
          <Dropdown.Item as={Link} text="2020 Census" to="/census" />
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item className="menuItem" as={Link} content="Resources &amp; Events" to="/member-resources" />
      <Menu.Item className="menuItem" as={Link} content="Press &amp; Media" to="/press-media" />
      <Menu.Item className="menuItem" as={Link} content="Newsletter" to="https://cvcallnce.com/newsletter" />
    </>
  )
}

interface IMenuBarProps {
  className?: string;
  sidebarVisible:boolean;
  setSidebarVisible: (visible:boolean)=>void;
}
const MenuBar = ({className, sidebarVisible, setSidebarVisible }:IMenuBarProps) =>{
  return(
    <Menu inverted fixed="top" className={className} >
      <Menu.Menu position="right">
        <MenuItems />
        <Menu.Item as={Link} to="/">
          <img src={CivicAllianceLogo} />
        </Menu.Item>
        <Menu.Item icon="bars" className="mobileMenuBars" onClick={()=> setSidebarVisible(!sidebarVisible) }/>
      </Menu.Menu>
    </Menu>
  )
}

const NavigationBarContainer = styled.div`
  &&&{
    .mobileMenuBars{
      display: none;
    }
    .headerMenu{
      .menuItem {
        display: flex;
      }
    }
  }
  @media (max-width: 960px){
    &&&{
      .mobileMenuBars{
        display: flex;
      }
      .headerMenu{
        .menuItem{
          display: none;
        }
      }
    }
  }
  //allow dropdown to open within sidebar
  &&&{
    .ui.sidebar {
      .menu.visible{
        position: relative;
        top: 10px;
        left: 0;
        background: none;
        box-shadow: none;
        border: none;
        border-radius: initial;
      }
    }
  }
`;


const NavigationBar = ()=>{
  const [sidebarVisible, setSidebarVisible] = useState(false);

  return(
    <NavigationBarContainer>
      <MenuBar className="headerMenu" setSidebarVisible={setSidebarVisible}  sidebarVisible={sidebarVisible}/>
      <Sidebar 
        as={Menu} 
        direction="right"
        animation="overlay" 
        vertical
        visible={sidebarVisible} 
      >
        <Menu.Item onClick={()=> setSidebarVisible(false)}>
          &nbsp; <Icon name="window close outline" />
        </Menu.Item>
        <MenuItems />
      </Sidebar>
    </NavigationBarContainer>
  )
}

export default NavigationBar
